const ChatIcon = (): JSX.Element => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.43223 21.8502C7.83362 21.8502 8.1114 21.6396 8.58247 21.216L11.7756 18.404H17.9668C20.5631 18.404 22 16.9276 22 14.3708V7.18355C22 4.62675 20.5631 3.15039 17.9668 3.15039H6.03315C3.43691 3.15039 2 4.6195 2 7.18355V14.3708C2 16.9349 3.43691 18.404 6.03315 18.404H6.54168V20.8243C6.54168 21.4524 6.86698 21.8502 7.43223 21.8502ZM7.75916 20.381V17.7151C7.75916 17.2735 7.59248 17.1068 7.15083 17.1068H6.04039C4.20828 17.1068 3.29719 16.1357 3.29719 14.3636V7.19079C3.29719 5.4231 4.20828 4.45565 6.04039 4.45565H17.9596C19.7772 4.45565 20.7028 5.4231 20.7028 7.19079V14.3636C20.7028 16.1357 19.7772 17.1068 17.9596 17.1068H11.7437C11.2884 17.1068 11.0509 17.1776 10.7281 17.5057L7.75916 20.381Z"
        fill="black"
      />
    </svg>
  );
};

export { ChatIcon };
