import { FullStory, init, isInitialized } from "@fullstory/browser";
import { env, Env } from "../helpers/env";
import { logger } from "./logger";

const ORG_ID: Record<Env, string> = {
  production: "o-1ED2V5-na1",
  staging: "o-1ED2V5-na1",
};

const initFullstory = () => {
  if (env === "production" || env === "staging") {
    init(
      {
        orgId: ORG_ID[env],
        recordCrossDomainIFrames: true,
      },
      ({ sessionUrl }) => {
        logger.info(`Fullstory Session Support: ${sessionUrl}`);
      }
    );
  }
};

const sendTrackingEvent = (
  eventName: string,
  properties: { [key: string]: any }
) => {
  if (isInitialized()) {
    FullStory("trackEvent", { name: eventName, properties });
  }
};

export { initFullstory, sendTrackingEvent };
