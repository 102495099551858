import React from "react";
import styled from "@emotion/styled/macro";
import { H2 } from "../atoms/H2";
import { CallCard } from "../molecules/CallCard";
import { CallCardRow } from "./CallCardRow";
import { LicensingCardRow } from "./LicensingCardRow";

const Accordian = styled.div`
  flex: 0 1 auto;
  align-self: auto;
  text-align: left;
  font-style: normal;
  height: auto;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: black;
  position: relative;
  max-width: 920px;
  @media (max-width: 639px) {
    width: 112%;
    margin-left: -6%;
  }

  & > details {
    color: black;
    border-bottom: 0.5px #5c5a54 solid;
    flex-direction: row-reverse;
    display: flex;
    justify-items: flex-start:
    justify-items: flex-start;
    @media (max-width: 639px) {
      padding: 0 1em 0 1em;
    }
  }
  details > summary {
    margin: 16px 0 16px 0;
    list-style: none;
    cursor: pointer;
  }
  summary::-webkit-details-marker {
    display: none;
  }

  summary {
    margin-bottom: 16px;
    @media (max-width: 639px) {
      padding: 0;
      font-size: 1.5rem;
       flex-direction: row;
       justify-content: space-between;
       
    display: flex;
    }

  }

  summary::after {
    content: " ⌃";
    float: right;
    transform: rotate(180deg);
    padding-right: 6px;
    cursor: pointer;
    font-weight: 300;
    font-size: 1.5rem;
    @media (max-width: 639px) {
display: flex;
flex-direction: row;
align-self: flex-start;
    }

  }
  details[open] summary:after {
    content: " ⌃";
    transform: rotate(0deg);
    margin-top: 15px;
    margin-right: -6px;
    cursor: pointer;
    font-size: 1.5rem;
    @media (max-width: 639px) {
display: flex;
flex-direction: row;
justify-self: center;
    }

  }
`;

const ListWrapper = styled.div`
  width: 420px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 639px) {
    width: 100%;
  }

  ul {
    display: flex;
    flex-direction: column;
  }
  li {
    font-size: 14px;
    margin-left: -38px;
    font-weight: 500;
    line-height: 20px;
    color: #6f6d68;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 0.5px #5c5a54 solid;
    padding: 18px 0px 18px 0px;
  }
`;

const ExtensionBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Ext = styled.h4`
  padding: 0px;
  margin: 0px;
  color: black;
`;

const TwoCardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
`;

const details = document.querySelectorAll("details");

details.forEach((targetDetail) => {
  targetDetail.addEventListener("click", () => {
    details.forEach((detail) => {
      if (detail !== targetDetail) {
        detail.removeAttribute("open");
      }
    });
  });
});

const PreSRA = () => {
  return (
    <div className="FirstTab">
      <Accordian>
        <details>
          <summary>Sales Support</summary>
          <CallCardRow
            callNumberDisplay="888-840-7180"
            callNumberLink="8888407180"
            textNumberDisplay="385-317-0193"
            textNumberLink="13853170193"
            email="PRESRA@sunrun.com"
          />
          <H2 children="Call Extension Numbers" />
          <ExtensionBox>
            <ListWrapper>
              <ul>
                <li>
                  Verification Call<Ext>1,1,1</Ext>
                </li>
                <li>
                  HIS Verification Call<Ext>1,1,2</Ext>
                </li>
                <li>
                  Lead Creation, SPLAT Support Pin Placement Update, Bright Path
                  Support<Ext>1,2,1</Ext>
                </li>
                <li>
                  CAP Support<Ext>1,1,2</Ext>
                </li>
                <li>
                  Contract Change Updates, Project Updates<Ext>1,2,9</Ext>
                </li>
                <li>
                  Contact, Adress, Utility Updates<Ext>1,2,7</Ext>
                </li>
              </ul>
            </ListWrapper>
            <ListWrapper>
              <ul>
                <li>
                  IT Support<Ext>1,6,2</Ext>
                </li>
                <li>
                  Password Assistance<Ext>1,6,1</Ext>
                </li>
                <li>
                  Pending Task Support Exception Requests
                  <Ext>1,3</Ext>
                </li>
                <li>
                  Home Upgrades<Ext>1,5</Ext>
                </li>
                <li>
                  Athlete to Expert Transfer<Ext>1,2,4</Ext>
                </li>
              </ul>
            </ListWrapper>
          </ExtensionBox>
        </details>
        <details>
          <summary>Licensing</summary>
          <LicensingCardRow />
          <H2 children="Call Extension Numbers" />
          <ExtensionBox>
            <ListWrapper>
              <ul>
                <li>
                  CA Livescan Payments<Ext>1,1,1</Ext>
                </li>
                <li>
                  Any other CA HIS Licensing<Ext>1,1,2</Ext>
                </li>
                <li>
                  MD & CT HIS Licensing<Ext>1,2,1</Ext>
                </li>
                <li>
                  West Coast Solicitor Licensing<Ext>1,1,2</Ext>
                </li>
              </ul>
            </ListWrapper>
            <ListWrapper>
              <ul>
                <li>
                  East Coast Solicitor Licensing<Ext>1,2,9</Ext>
                </li>
                <li>
                  Inside Sales, Telemarketing License<Ext>1,2,7</Ext>
                </li>
                <li>
                  Title Change, Location Transfers
                  <Ext>1,6,2</Ext>
                </li>
                <li>
                  Any other questions<Ext>1,6,1</Ext>
                </li>
              </ul>
            </ListWrapper>
          </ExtensionBox>
        </details>
        <details>
          <summary>Referral/Promotions</summary>
          <CallCard
            title="Submit a request via email"
            buttonText="Promos@sunrun.com"
            ButtonLink="mailto:Promos@sunrun.com"
            imageType="email"
            shortCard
          />
        </details>
        <details>
          <summary>Credit Support</summary>
          <TwoCardRow>
            <CallCard
              title="Text"
              Line1="Mon - Sun · 9 am - 8 pm MST"
              Line2="Limited hours on holidays."
              buttonText="Chat Now"
              Phone="385-317-0193"
              secondIcon="chat"
              ButtonLink="sms:+13853170193"
            />
            <CallCard
              title="Submit a request via email"
              buttonText="Credit@sunrun.com"
              ButtonLink="mailto:Credit@sunrun.com"
              imageType="email"
            />
          </TwoCardRow>
        </details>
      </Accordian>
    </div>
  );
};
export { PreSRA };
