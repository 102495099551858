import styled from "@emotion/styled/macro";

const SpanType = styled.h1`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #6f6d68;
`;

type SpanProps = {
  children: string;
};

const Span = ({ children }: SpanProps) => <SpanType>{children}</SpanType>;

export { Span };
