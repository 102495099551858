import { useState } from "react";
import { publish, useSubscribe } from "./Messages";

const authkeyCache = "";

const useAuthkey = () => {
  const [authkey, setAuthkey] = useState(authkeyCache);

  if (!authkey) publish.queryAuthToken();
  useSubscribe((message) => {
    if (message.type === "EVENT:AUTH_TOKEN") {
      setAuthkey(message.payload.token);
    }
  });

  return authkey;
};

export { useAuthkey };
