import React from "react";
import styled from "@emotion/styled/macro";
import { ReactComponent as WhiteSpinner } from "../../assets/images/spinner-white.svg";
import { ReactComponent as BlackSpinner } from "../../assets/images/spinner-black.svg";

type PropTypes = {
  className?: string;
  height?: number;
  width?: number;
  color?: "black" | "white";
};

const LoadingIndicator = ({
  className,
  height = 30,
  width = 30,
  color = "white",
}: PropTypes) => {
  switch (color) {
    case "black":
      return (
        <BlackSpinnerAnim
          className={className}
          height={`${height}px`}
          width={`${width}px`}
        />
      );
    case "white":
      return (
        <WhiteSpinnerAnim
          className={className}
          height={`${height}px`}
          width={`${width}px`}
        />
      );
  }
};

const BlackSpinnerAnim = styled(BlackSpinner)`
  @keyframes spin {
    from {
      transform: rotate(0turn);
    }
    to {
      transform: rotate(-1turn);
    }
  }
  animation: spin;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;
const WhiteSpinnerAnim = styled(WhiteSpinner)`
  @keyframes spin {
    from {
      transform: rotate(0turn);
    }
    to {
      transform: rotate(-1turn);
    }
  }
  animation: spin;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export { LoadingIndicator };
