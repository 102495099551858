import React from "react";
import "./App.css";
import { SupportPage } from "./components/pages/SupportPage";
import { useUserProfile } from "./providers/UserProfile";
import { ChannelPartnerSupportPage } from "./components/pages/ChannelPartnerSupportPage";
import { LoadingIndicator } from "./components/atoms/LoadingSpinner";

function App() {
  const userProfile = useUserProfile();
  if (!userProfile) return <LoadingIndicator />;

  return (
    <div className="App">
      {userProfile.splatRole === "Integrated Partner" ? (
        <ChannelPartnerSupportPage />
      ) : (
        <SupportPage />
      )}
    </div>
  );
}
export { App };
