import React from "react";
import styled from "@emotion/styled/macro";
import { Links } from "../organisms/Links";
import { Tabs } from "../organisms/Tabs";

const Headline = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 44px;
  text-align: justify;
`;

const Container = styled.div`
  margin: 5%;
  width: 90%;
`;

const SupportPage = () => {
  return (
    <div className="App" data-testid="support-page">
      <Container>
        <Headline>
          <p>Support</p>
        </Headline>
        <Links />
        <Tabs />
      </Container>
    </div>
  );
};

export { SupportPage };
