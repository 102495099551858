import React from "react";
import styled from "@emotion/styled/macro";
import { H2 } from "../atoms/H2";
import { CallCard } from "../molecules/CallCard";
import { ChannelPostCallCardRow } from "./ChannelPostCallCardRow";

const Accordian = styled.div`
  flex: 0 1 auto;
  align-self: auto;
  text-align: left;
  font-style: normal;
  height: auto;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: black;
  position: relative;
  max-width: 920px;
  @media (max-width: 639px) {
    width: 112%;
    margin-left: -6%;
  }

  & > details {
    color: black;
    border-bottom: 0.5px #5c5a54 solid;
    flex-direction: row-reverse;
    display: flex;
    justify-items: flex-start:
    justify-items: flex-start;
    @media (max-width: 639px) {
      padding: 0 1em 0 1em;
    }
  }
  details > summary {
    margin: 16px 0 16px 0;
    list-style: none;
    cursor: pointer;
  }
  summary::-webkit-details-marker {
    display: none;
  }

  summary {
    margin-bottom: 16px;
    padding: 0;
      font-size: 1.5rem;
       flex-direction: row;
       justify-content: space-between;
       
    display: flex;
    @media (max-width: 639px) {
      padding: 0;
      font-size: 1.5rem;
       flex-direction: row;
       justify-content: space-between;
       
    display: flex;
    }

  }

  summary::after {
    content: " ⌃";
    float: right;
    transform: rotate(180deg);
    padding-right: 6px;
    cursor: pointer;
    font-weight: 300;
    font-size: 1.5rem;
    @media (max-width: 639px) {
display: flex;
flex-direction: row;
align-self: flex-start;
    }

  }
  details[open] summary:after {
    content: " ⌃";
    transform: rotate(0deg);
    margin-top: 15px;
    margin-right: -6px;
    cursor: pointer;
    font-size: 1.5rem;
    @media (max-width: 639px) {
display: flex;
flex-direction: row;
justify-self: center;
    }

  }
`;

const ListWrapper = styled.div`
  width: 420px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 639px) {
    width: 100%;
  }

  ul {
    display: flex;
    flex-direction: column;
  }
  li {
    font-size: 14px;
    margin-left: -38px;
    font-weight: 500;
    line-height: 20px;
    color: #6f6d68;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 0.5px #5c5a54 solid;
    padding: 18px 0px 18px 0px;
  }
`;

const ExtensionBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Ext = styled.h4`
  padding: 0px;
  margin: 0px;
  color: black;
`;

const details = document.querySelectorAll("details");

details.forEach((targetDetail) => {
  targetDetail.addEventListener("click", () => {
    details.forEach((detail) => {
      if (detail !== targetDetail) {
        detail.removeAttribute("open");
      }
    });
  });
});

const ChannelPartnerPostSRA = () => {
  return (
    <div className="FirstTab">
      <Accordian>
        <details>
          <summary>Sales Support</summary>
          <ChannelPostCallCardRow />
          <H2 children="Call Extension Numbers" />
          <ExtensionBox>
            <ListWrapper>
              <ul>
                <li>
                  True-Up Support, Project Updates<Ext>1,2,9</Ext>
                </li>
              </ul>
            </ListWrapper>
          </ExtensionBox>
        </details>
        <details>
          <summary>NTP Pending Task Support</summary>
          <CallCard
            title="Submit a request via email"
            buttonText="installationreview@sunrun.com"
            imageType="email"
            ButtonLink="mailto:installationreview@sunrun.com"
            shortCard
          />
        </details>
        <details>
          <summary>Referral / Promotion Support</summary>
          <CallCard
            title="Submit a request via email"
            buttonText="promos@sunrun.com "
            imageType="email"
            ButtonLink="mailto:promos@sunrun.com "
            shortCard
          />
        </details>
      </Accordian>
    </div>
  );
};
export { ChannelPartnerPostSRA };
