import { useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";
import memoize from "lodash/memoize";
import { AmplifyGet } from "@sunrun/sales-experience-shared/amplify-api-wrapper";
import { getSplatUserType, UserProfile } from "@sunrun/sales-experience-shared";
import { useAuthkey } from "./Auth";
import { logger } from "./logger";

const getUserProfile = memoize(
  async (authKey: string): Promise<UserProfile> => {
    const response = await AmplifyGet<{ userProfile: UserProfile }>(
      "Get User Profile",
      "salesSupport",
      "/getUserProfile",
      {
        headers: {
          Authorization: `Bearer ${authKey}`,
        },
      },
      logger
    );
    return response.userProfile.sfdcContact ?? response.userProfile;
  }
);

const useUserProfile = singletonHook(undefined, (): UserProfile | undefined => {
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const authKey = useAuthkey();
  useEffect(() => {
    if (authKey) {
      getUserProfile(authKey)
        .then((userProfile: UserProfile) => {
          userProfile.splatRole = getSplatUserType(userProfile);
          setUserProfile(userProfile);
        })
        .catch(() => {
          // TODO: Fix under SPLAT1-7038
          // Do nothing
        });
    }
  }, [authKey]);
  return userProfile;
});

export type { UserProfile };
export { getUserProfile, useUserProfile, getSplatUserType };
