import styled from "@emotion/styled/macro";
import { CallCard } from "../molecules/CallCard";

const CallCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 24px 0px 0px;
  gap: 16px;
  max-width: 920px;
  left: 149px;
  top: 413px;
  margin: 16px 0px 16px 0px;
`;

const ChannelPostCallCardRow = () => {
  return (
    <CallCardWrapper>
      <CallCard
        title="Call"
        buttonText="Call Now"
        Line1="Mon - Fri · 8 am - 7 pm MST"
        Line2="Sat · 8 am - 5 pm MST"
        Phone="888-840-7180"
        imageType="phone"
        ButtonLink="tel:8888407180"
      />
      <CallCard
        title="Text"
        buttonText="Chat Now"
        Line1="Mon - Fri · 8 am - 7 pm MST"
        Line2="Sat · 8 am - 5 pm MST"
        Phone="385-317-0250"
        secondIcon="chat"
        ButtonLink="sms:+13853170250"
      />
      <CallCard
        title="Submit a request via email"
        buttonText="postsra@sunrun.com"
        imageType="email"
        ButtonLink="mailto:postsra@sunrun.com"
      />
    </CallCardWrapper>
  );
};

export { ChannelPostCallCardRow };
