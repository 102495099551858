import React from "react";
import styled from "@emotion/styled/macro";
import { H2 } from "../atoms/H2";
import { CallCard } from "../molecules/CallCard";

const Accordian = styled.div`
  flex: 0 1 auto;
  align-self: auto;
  text-align: left;
  font-style: normal;
  height: auto;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: black;
  position: relative;
  max-width: 920px;
  @media (max-width: 639px) {
    width: 112%;
    margin-left: -6%;
  }

  & > details {
    color: black;
    border-bottom: 0.5px #5c5a54 solid;
    flex-direction: row-reverse;
    display: flex;
    justify-items: flex-start:
    justify-items: flex-start;
    @media (max-width: 639px) {
      padding: 0 1em 0 1em;
    }
  }
  details > summary {
    margin: 16px 0 16px 0;
    list-style: none;
    cursor: pointer;
  }
  summary::-webkit-details-marker {
    display: none;
  }

  summary {
    margin-bottom: 16px;
    @media (max-width: 639px) {
      padding: 0;
      font-size: 1.5rem;
       flex-direction: row;
       justify-content: space-between;
       
    display: flex;
    }

  }

  summary::after {
    content: " ⌃";
    float: right;
    transform: rotate(180deg);
    padding-right: 6px;
    cursor: pointer;
    font-weight: 300;
    font-size: 1.5rem;
    @media (max-width: 639px) {
display: flex;
flex-direction: row;
align-self: flex-start;
    }

  }
  details[open] summary:after {
    content: " ⌃";
    transform: rotate(0deg);
    margin-top: 15px;
    margin-right: -6px;
    cursor: pointer;
    font-size: 1.5rem;
    @media (max-width: 639px) {
display: flex;
flex-direction: row;
justify-self: center;
    }

  }
`;

const BulletedList = styled.div`
  li {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1rem;
    color: #6f6d68;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0px 0px 24px 0px;
  }
`;

const TwoCardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
`;

const details = document.querySelectorAll("details");

details.forEach((targetDetail) => {
  targetDetail.addEventListener("click", () => {
    details.forEach((detail) => {
      if (detail !== targetDetail) {
        detail.removeAttribute("open");
      }
    });
  });
});

const PostInstall = () => {
  return (
    <div className="FirstTab">
      <Accordian>
        <details>
          <summary>Customer Support Specialist</summary>
          <H2 children="Verify CSS on Electriciation Ops Roster" />
          <BulletedList>
            <ul>
              <li>Schedules installations & revisits</li>
              <li>
                Point of contact for customer from inspection to confirmation of
                production
              </li>
              <li>System activation support</li>
            </ul>
          </BulletedList>
        </details>
        <details>
          <summary>Post-PTO Support</summary>
          <BulletedList>
            <ul>
              <li>Systems not producing</li>
              <li>Billing issues</li>
              <li>Roof leaks</li>
              <li>Post-PTO service requests</li>
            </ul>
          </BulletedList>
          <TwoCardRow>
            <CallCard
              title="Submit a request via email"
              buttonText="internalescalations@sunrun.com"
              ButtonLink="mailto:internalescalations@sunrun.com"
              imageType="email"
              shortCard
            />
            <CallCard
              title="Call"
              buttonText="Call Now"
              Line1="Mon - Sat · 6 am - 8 pm MST"
              Line2="Sunday · 7 am - 5 pm MST"
              Phone="855-478-6786"
              secondIcon="phone"
              ButtonLink="tel:+18554786786"
              imageType="phone"
              shortCard
            />
          </TwoCardRow>
        </details>
        <details>
          <summary>Commissions</summary>
          <CallCard
            title="Submit a request via email"
            buttonText="SolarCommissions@sunrun.com"
            ButtonLink="mailto:SolarCommissions@sunrun.com"
            imageType="email"
            shortCard
          />
        </details>
        <details>
          <summary>Rules of Engagement / Account Disputes</summary>
          <CallCard
            title="Submit a request via email"
            buttonText="ComplianceOps@sunrun.com"
            ButtonLink="mailto:ComplianceOps@sunrun.com"
            imageType="email"
            shortCard
          />
        </details>
      </Accordian>
    </div>
  );
};
export { PostInstall };
