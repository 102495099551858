import styled from "@emotion/styled/macro";
import React, { useState } from "react";
import { PreSRA } from "./PreSRA";
import { PostSRA } from "./PostSRA";
import { PostInstall } from "./PostInstall";

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  @media (max-width: 380px) {
    gap: 6px;
  }
  margin-bottom: 64px;

  & button {
    padding: 4px 16px 4px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    @media (max-width: 380px) {
      font-size: 0.8rem;
    }
    line-height: 19px;
    color: black;
    text-decoration: none;
    background-color: transparent;
    border: none;
    &.active {
      background: #000000;
      border-radius: 32px;
      color: #fffbf0;
    }
  }

  & button:hover {
    background: #000000;
    border-radius: 32px;
    color: #fffbf0;
    cursor: pointer;
  }

  & button:active {
    color: red;
  }

  & button:focus {
    background: #000000;
    border-radius: 32px;
    color: #fffbf0;
  }
`;
const SalesHeadline = styled.div`
  color: black;
  & p {
    text-align: justify;
    font-style: normal;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 2rem;
  }
`;
const TabContent = styled.div``;

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  return (
    <>
      <SalesHeadline>
        {" "}
        <p>Sales Support Contacts</p>
      </SalesHeadline>
      <TabContainer>
        <button
          onClick={() => setActiveTab("tab1")}
          className={activeTab === "tab1" ? "active" : ""}
        >
          Pre-SRA
        </button>
        <button
          onClick={() => setActiveTab("tab2")}
          className={activeTab === "tab2" ? "active" : ""}
        >
          Post-SRA
        </button>
        <button
          onClick={() => setActiveTab("tab3")}
          className={activeTab === "tab3" ? "active" : ""}
        >
          Post-Install
        </button>
      </TabContainer>
      <TabContent>
        {activeTab === "tab1" ? <PreSRA /> : ""}
        {activeTab === "tab2" ? <PostSRA /> : ""}
        {activeTab === "tab3" ? <PostInstall /> : ""}
      </TabContent>
    </>
  );
};

export { Tabs };
