import React from "react";
import styled from "@emotion/styled/macro";
import { ChannelPartnerTabs } from "../organisms/ChannelPartnerTabs";

const Headline = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 44px;
  text-align: justify;
`;

const Container = styled.div`
  margin: 5%;
  width: 90%;
`;

const ChannelPartnerSupportPage = () => {
  return (
    <div className="App" data-testid="channel-partner-support-page">
      <Container>
        <Headline>
          <p>Support</p>
        </Headline>
        <ChannelPartnerTabs />
      </Container>
    </div>
  );
};

export { ChannelPartnerSupportPage };
