import styled from "@emotion/styled/macro";

const H2Type = styled.span`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  padding-top: 16px;
`;
type H2Props = {
  children: string;
};

const H2 = ({ children }: H2Props) => <H2Type>{children}</H2Type>;

export { H2 };
