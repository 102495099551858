import React from "react";

const LinkIcon = (): JSX.Element => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3625 2.99548C21.6085 3.22922 21.6927 3.56603 21.6067 3.85643L21.5189 12.3128C21.5142 12.7689 21.1382 13.1124 20.6792 13.08C20.2202 13.0477 19.852 12.6517 19.8567 12.1955L19.9237 5.74718L10.2932 15.8692C9.98708 16.1909 9.46165 16.1882 9.11957 15.8632C8.77749 15.5383 8.74831 15.014 9.05439 14.6923L18.8413 4.40596H12.0463C11.5881 4.40596 11.1942 4.03526 11.1664 3.57798C11.1386 3.1207 11.4875 2.75 11.9457 2.75H20.7276L20.7428 2.75014C20.9613 2.74616 21.1862 2.82803 21.3625 2.99548ZM3.32121 7.70042C2.86302 7.70042 2.48775 8.07196 2.48301 8.53029L2.34965 21.4201C2.34491 21.8785 2.7125 22.25 3.17069 22.25H15.9272C16.3854 22.25 16.7607 21.8785 16.7655 21.4201L16.8321 14.9752C16.8369 14.5169 16.4693 14.1453 16.0111 14.1453C15.5529 14.1453 15.1776 14.5169 15.1729 14.9752L15.1148 20.5902H4.01747L4.13365 9.36017H9.68232C10.1405 9.36017 10.5158 8.98862 10.5205 8.53029C10.5253 8.07196 10.1577 7.70042 9.69949 7.70042H3.32121Z"
        fill="black"
      />
    </svg>
  );
};

export { LinkIcon };
