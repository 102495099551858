import styled from "@emotion/styled/macro";
import { CallCard } from "../molecules/CallCard";

const CallCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 24px 0px 0px;
  gap: 16px;
  max-width: 920px;
  left: 149px;
  top: 413px;
  margin: 16px 0px 16px 0px;
`;

type CallCardRowProps = {
  callNumberDisplay: string;
  callNumberLink: string;
  textNumberDisplay: string;
  textNumberLink: string;
  email: string;
};

const CallCardRow = ({
  callNumberDisplay,
  callNumberLink,
  textNumberDisplay,
  textNumberLink,
  email,
}: CallCardRowProps) => {
  return (
    <CallCardWrapper>
      <CallCard
        title="Call"
        buttonText="Call Now"
        Line1="Mon - Sat · 7 am - 10 pm MST"
        Line2="Sunday · 7 am - 8 pm MST"
        Phone={callNumberDisplay}
        imageType="phone"
        ButtonLink={`tel:${callNumberLink}`}
      />
      <CallCard
        title="Text"
        buttonText="Chat Now"
        Line1="Mon - Sun · 7 am - 8 pm MST"
        Phone={textNumberDisplay}
        secondIcon="chat"
        ButtonLink={`sms:+${textNumberLink}`}
      />
      <CallCard
        title="Submit a request via email"
        buttonText={email}
        imageType="email"
        ButtonLink={`mailto:${email}`}
      />
    </CallCardWrapper>
  );
};

export { CallCardRow };
