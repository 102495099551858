const PhoneIcon = (): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8944 22.0047C18.6302 22.0047 19.8302 21.5257 20.8863 20.3732C20.9585 20.2918 21.0216 20.2104 21.0856 20.1423C21.7085 19.4554 22 18.7704 22 18.1242C22 17.4154 21.6019 16.7513 20.7467 16.169L17.7284 14.0774C16.8339 13.4631 15.7887 13.3813 14.8945 14.2541L14.1221 15.0224C13.8877 15.2568 13.6611 15.2815 13.4048 15.1325C12.8659 14.8016 11.7281 13.8396 10.8983 13.014C10.0111 12.1308 9.27294 11.2917 8.86303 10.5999C8.71408 10.3386 8.73872 10.117 8.97317 9.88257L9.75054 9.10108C10.6233 8.22005 10.5416 7.16158 9.91819 6.27128L7.83982 3.26206C7.24424 2.40277 6.58428 2.00877 5.87128 1.99553C5.23426 1.98228 4.54512 2.2912 3.86237 2.90996C3.781 2.98218 3.71288 3.05028 3.6356 3.1184C2.48716 4.15714 2 5.36627 2 7.08472C2 10.0181 3.85984 13.5882 7.14433 16.8645C10.4073 20.1234 13.961 22.0047 16.8944 22.0047ZM16.9035 20.5844C14.2572 20.621 10.9019 18.6039 8.1971 15.899C5.46987 13.1901 3.37454 9.73018 3.42027 7.07967C3.43856 5.92857 3.83612 4.93331 4.66004 4.19172C4.72311 4.13779 4.77704 4.093 4.84012 4.03905C5.18324 3.73252 5.55376 3.56302 5.86401 3.56302C6.1802 3.56302 6.45758 3.7019 6.66776 4.02222L8.68214 7.03649C8.92201 7.38965 8.95487 7.78485 8.57067 8.15578L7.68633 9.03599C7.02453 9.70287 7.05791 10.5443 7.53421 11.2042C8.07676 11.9702 9.0671 13.1045 9.94297 13.9703C10.8138 14.8493 12.0386 15.9229 12.8004 16.4705C13.4645 16.9417 14.3018 16.9802 14.9636 16.3092L15.8398 15.4249C16.2199 15.0539 16.615 15.0827 16.9723 15.3226L19.9774 17.3369C20.3069 17.5471 20.4325 17.8153 20.4325 18.1315C20.4325 18.4591 20.2763 18.8215 19.9524 19.1687C19.9076 19.2317 19.8669 19.2816 19.813 19.3355C19.0673 20.1727 18.0587 20.5661 16.9035 20.5844Z"
        fill="black"
      />
    </svg>
  );
};

export { PhoneIcon };
