import React from "react";
import styled from "@emotion/styled/macro";
import { H2 } from "../atoms/H2";
import { Span } from "../atoms/Span";
import { H4 } from "../atoms/H4";
import { CallCard } from "../molecules/CallCard";
import { CallCardRow } from "./CallCardRow";

const Accordian = styled.div`
  flex: 0 1 auto;
  align-self: auto;
  text-align: left;
  font-style: normal;
  height: auto;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: black;
  position: relative;
  max-width: 920px;
  @media (max-width: 639px) {
    width: 112%;
    margin-left: -6%;
  }

  & > details {
    color: black;
    border-bottom: 0.5px #5c5a54 solid;
    flex-direction: row-reverse;
    display: flex;
    justify-items: flex-start;
    @media (max-width: 639px) {
      padding: 0 1em 0 1em;
    }
  }
  details > summary {
    margin: 16px 0 16px 0;
    list-style: none;
    cursor: pointer;
  }
  summary::-webkit-details-marker {
    display: none;
  }

  summary {
    margin-bottom: 16px;
    padding: 0;
    font-size: 1.5rem;
    flex-direction: row;
    justify-content: space-between;

    display: flex;
    @media (max-width: 639px) {
      padding: 0;
      font-size: 1.5rem;
      flex-direction: row;
      justify-content: space-between;

      display: flex;
    }
  }

  summary::after {
    content: " ⌃";
    float: right;
    transform: rotate(180deg);
    padding-right: 6px;
    cursor: pointer;
    font-weight: 300;
    font-size: 1.5rem;
    @media (max-width: 639px) {
      display: flex;
      flex-direction: row;
      align-self: flex-start;
    }
  }
  details[open] summary:after {
    content: " ⌃";
    transform: rotate(0deg);
    margin-top: 15px;
    margin-right: -6px;
    cursor: pointer;
    font-size: 1.5rem;
    @media (max-width: 639px) {
      display: flex;
      flex-direction: row;
      justify-self: center;
    }
  }
`;

const SummaryTitleContainer = styled.div`
  @media (max-width: 400px) {
    width: 200px;
  }
`;

const ListWrapper = styled.div`
  width: 420px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 639px) {
    width: 100%;
  }

  ul {
    display: flex;
    flex-direction: column;
  }
  li {
    font-size: 14px;
    margin-left: -38px;
    font-weight: 500;
    line-height: 20px;
    color: #6f6d68;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 0.5px #5c5a54 solid;
    padding: 18px 0px 18px 0px;
  }
`;

const BulletedList = styled.div`
  li {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1rem;
    color: #6f6d68;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0px 0px 24px 0px;
  }
`;

const ExtensionBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Ext = styled.h4`
  padding: 0px;
  margin: 0px;
  color: black;
`;
const BottomBorder = styled.div`
  border-bottom: 0.5px #5c5a54 solid;
`;

const TwoCardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
`;

const details = document.querySelectorAll("details");

details.forEach((targetDetail) => {
  targetDetail.addEventListener("click", () => {
    details.forEach((detail) => {
      if (detail !== targetDetail) {
        detail.removeAttribute("open");
      }
    });
  });
});

const PostSRA = () => {
  let date = new Date().toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  return (
    <div className="FirstTab">
      <Accordian>
        <details>
          <summary>Sales Support</summary>
          <CallCardRow
            callNumberDisplay="844-257-7555"
            callNumberLink="8442577555"
            textNumberDisplay="385-317-0250"
            textNumberLink="13853170250"
            email="POSTSRA@sunrun.com"
          />
          <H2 children="Call Extension Numbers" />
          <ExtensionBox>
            <ListWrapper>
              <ul>
                <li>
                  Contract Change Updates, Project Updates<Ext>1,2,9</Ext>
                </li>
                <li>
                  CAP Support<Ext>1,2,8</Ext>
                </li>
              </ul>
            </ListWrapper>
            <ListWrapper>
              <ul>
                <li>
                  Site Audit Scheduling<Ext>1,2,6</Ext>
                </li>
              </ul>
            </ListWrapper>
          </ExtensionBox>
        </details>
        <details>
          <summary>
            <SummaryTitleContainer>
              Customer Huddle / Home Upgrades
            </SummaryTitleContainer>
          </summary>
          <H4>{date}</H4>
          <H2 children="Designers are coordinating and leading huddles" />
          <BottomBorder>
            <Span>
              Sales rep attendance is optional and projects with minor changes
              will bypass the huddle.
            </Span>
          </BottomBorder>
          <TwoCardRow>
            <CallCard
              title="Submit a request via email"
              buttonText="HomeUpgrades@sunrun.com"
              ButtonLink="mailto:HomeUpgrades@sunrun.com"
              imageType="email"
              shortCard
            />
            <CallCard
              title="Call"
              buttonText="Call Now"
              Phone="888-840-7180 · opt 1,5"
              secondIcon="phone"
              imageType="phone"
              ButtonLink="tel:+18888407180"
              shortCard
            />
          </TwoCardRow>
        </details>
        <details>
          <summary>Post-CAP Support</summary>
          <H2 children="Customer Support Specialist" />
          <BulletedList>
            {" "}
            <ul>
              <li>Permitting</li>
              <li>HOA Approval</li>
              <li>Home updates / Scope of Work</li>
              <li>Utility Approval</li>
              <li>Single point of contact for customer updates</li>
            </ul>
          </BulletedList>
        </details>
        <details>
          <summary>Loan Support</summary>
          <TwoCardRow>
            <CallCard
              title="Submit a request via email"
              buttonText="BankFinanceSupport@sunrun.com"
              ButtonLink="mailto:BankFinanceSupport@sunrun.com"
              imageType="email"
              shortCard
            />
          </TwoCardRow>
        </details>
      </Accordian>
    </div>
  );
};
export { PostSRA };
