import styled from "@emotion/styled/macro";
import { H2 } from "../atoms/H2";
import { Span } from "../atoms/Span";
import { ChatIcon } from "../../assets/images/ChatIcon";
import { PhoneIcon } from "../../assets/images/PhoneIcon";
import { EmailIcon } from "../../assets/images/EmailIcon";

type CallCardProps = {
  buttonText: string;
  title: string;
  Line1?: string;
  Line2?: string;
  Phone?: string;
  imageType?: "phone" | "email" | "chat";
  secondIcon?: "phone" | "email" | "chat";
  ButtonLink?: string;
  shortCard?: boolean;
};

const Card = styled.div`
  width: 226px;
  max-width: 348px;
  height: 234px;
  border: 1px solid #d0ccc3;
  border-radius: 16px;
  @media (max-width: 639px) {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #d0ccc3;
    max-width: 100%;
    width: 100%;
    height: auto;
    padding-top: 0px;
    padding: 0px;
    padding-bottom: 20px;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  gap: 16px;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin-top: 16px;
  margin-bottom: 24px;

  &.ShortCard {
    width: auto;
    max-width: 348px;
    height: auto;
    @media (max-width: 639px) {
      max-width: 100%;
    }
  }
  a {
    color: white;
    text-decoration: none;
    font-size: 16px;
  }
`;

const HoursContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.div`
  max-width: 200px;
  @media (max-width: 639px) {
    max-width: 100%;
    width: 100%;
    font-size: 3rem;
    line-height: 10px;
  }
`;

const IconStyle = styled.div``;

const UpperCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 24px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const IconWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  @media (max-width: 639px) {
    align-self: flex-end;
  }
`;

const SecondIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const CallButton = styled.button`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  order: 4;
  justify-content: center;
  cursor: pointer;
  border: none;
  gap: 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  background: #181e3a;
  padding: 18px 24px;
  border-radius: 800px;
  width: 100%;
  height: 50px;
  @media (max-width: 639px) {
    padding: 18px;
  }
`;

const CallCard = ({
  buttonText,
  title,
  Line1,
  Line2,
  Phone,
  imageType,
  secondIcon,
  ButtonLink,
  shortCard,
}: CallCardProps): JSX.Element => {
  let cardHeight = "button";
  if (shortCard) {
    cardHeight += " ShortCard";
  }

  return (
    <Card className={cardHeight}>
      <HeadingWrapper>
        <Title>
          <H2>{title}</H2>
        </Title>
        <IconWrapper>
          <IconStyle>
            {imageType === "phone" ? (
              <PhoneIcon />
            ) : imageType === "email" ? (
              <EmailIcon />
            ) : (
              <ChatIcon />
            )}
          </IconStyle>
        </IconWrapper>
      </HeadingWrapper>
      <UpperCardContent>
        <HoursContent>
          {Line1 && <Span>{Line1}</Span>}
          {Line2 && <Span>{Line2}</Span>}
          {Phone && (
            <SecondIconWrapper>
              {secondIcon === "chat" ? (
                <ChatIcon />
              ) : secondIcon === "email" ? (
                <EmailIcon />
              ) : (
                <PhoneIcon />
              )}
              <Span>·</Span>
              <Span>{Phone}</Span>
            </SecondIconWrapper>
          )}
        </HoursContent>
      </UpperCardContent>

      <a href={ButtonLink} target="_blank" rel="noreferrer">
        <CallButton>{buttonText}</CallButton>
      </a>
    </Card>
  );
};

export { CallCard };
