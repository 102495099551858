import styled from "@emotion/styled/macro";
import { CallCard } from "../molecules/CallCard";

const CallCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 24px 0px 0px;
  gap: 16px;
  max-width: 920px;
  margin: 16px 0px 16px 0px;
`;

const LicensingCardRow = () => {
  return (
    <CallCardWrapper>
      <CallCard
        title="Call"
        buttonText="Call Now"
        Line1="Mon - Sat · 7 am - 5:30 pm MST"
        Phone="888-840-7180"
        imageType="phone"
        ButtonLink="tel:8888407180"
      />
      <CallCard
        title="Text"
        buttonText="Chat Now"
        Line1="Mon - Sat · 7 am - 8 pm MST"
        Phone="385-317-0193"
        secondIcon="chat"
        ButtonLink="sms:+13853170193"
      />
      <CallCard
        title="Submit a request via email"
        buttonText="Saleslicensing@sunrun.com"
        imageType="email"
        ButtonLink="mailto:Saleslicensing@sunrun.com"
      />
    </CallCardWrapper>
  );
};

export { LicensingCardRow };
