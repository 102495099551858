import styled from "@emotion/styled/macro";
import { LinkIcon } from "../../assets/images/LinkIcon";

const Link = styled.div`
  width: 239px;
  border-bottom: 1px solid;
  @media (max-width: 639px) {
    width: 100%;
    font-size: 20px;
  }
  & > p {
    text-align: justify;
  }
  & svg {
    float: right;
  }
  & a {
    cursor: pointer;
    text-decoration: none;
    color: black;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 64px;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Links = () => {
  return (
    <LinkContainer>
      <Link>
        <p>
          <a
            href="https://sunrun.service-now.com/help?id=sc_cat_item&sys_id=d42ed9524f858600d59d76601310c76a&sysparm_category=a3499ce4db570c10fb951e1339961972"
            target="_blank"
            rel="noreferrer"
          >
            Bug Request
            <LinkIcon />
          </a>
        </p>
      </Link>
      <Link>
        <p>
          <a href="https://help.sunrun.com/" target="_blank" rel="noreferrer">
            Sunrun IT Help Portal
            <LinkIcon />
          </a>
        </p>
      </Link>
    </LinkContainer>
  );
};

export { Links };
