import { useEffect } from "react";

type Source = "Support";

// Messages
type MessageQueryAuthToken = {
  type: "QUERY:AUTH_TOKEN";
  source: Source;
};
type MessageOnAuthToken = {
  type: "EVENT:AUTH_TOKEN";
  source: Source;
  payload: {
    token: string;
  };
};
type Message = MessageQueryAuthToken | MessageOnAuthToken;

type Subscriber = {
  id: string;
  action: (action: Message) => void;
};

let subscribers: Subscriber[] = [];

const subscribe = (action: Subscriber["action"]) => {
  const id = Math.random().toString(36);
  subscribers.push({ id, action });
  return () => {
    subscribers = subscribers.filter((subscriber) => subscriber.id !== id);
  };
};

const useSubscribe = (action: Subscriber["action"]) => {
  useEffect(() => {
    subscribe(action);
  });
};

window.addEventListener<Message | any>("message", (event) => {
  if (event.origin === window.origin && !event.origin.includes("localhost")) {
    return;
  }
  const message: Message = event.data;
  subscribers.forEach((subscriber) => subscriber.action(message));
});

const publishMessage = (message: Message) => {
  const event = message;
  window.parent.postMessage(event, "*");
};

const publish = {
  queryAuthToken: () => {
    const message: MessageQueryAuthToken = {
      source: "Support",
      type: "QUERY:AUTH_TOKEN",
    };
    publishMessage(message);
  },
};

export { useSubscribe, publish };
