const EmailIcon = (): JSX.Element => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.77642 15.7859H17.4451C19.0637 15.7859 20 14.8585 20 13.0417V2.9591C20 1.14229 19.0557 0.214844 17.2236 0.214844H2.5513C0.928278 0.214844 0 1.13509 0 2.9591V13.0417C0 14.8657 0.936321 15.7859 2.77642 15.7859ZM2.7354 14.5551C1.76275 14.5551 1.23884 14.0393 1.23884 13.0344V2.95829C1.23884 1.96151 1.76275 1.44562 2.7354 1.44562H17.2566C18.2148 1.44562 18.7612 1.96151 18.7612 2.96633V13.0425C18.7612 14.0393 18.2148 14.5551 17.2566 14.5551H2.7354ZM10.0257 10.3448C10.6293 10.3448 11.2305 10.1057 11.8478 9.49569L19.3135 2.17895L18.461 1.32641L11.1355 8.54946C10.7429 8.94207 10.3761 9.10602 10.0257 9.10602C9.66724 9.10602 9.29681 8.9304 8.90783 8.54946L1.55018 1.29427L0.705712 2.14678L8.1956 9.49569C8.81287 10.1057 9.41764 10.3448 10.0257 10.3448ZM18.3686 14.653L19.2059 13.8013L13.2021 7.9067L12.3612 8.74396L18.3686 14.653ZM0.819429 13.8113L1.66113 14.6747L7.69023 8.74396L6.8413 7.9067L0.819429 13.8113Z"
        fill="black"
      />
    </svg>
  );
};

export { EmailIcon };
