import styled from "@emotion/styled/macro";

type H4Props = {
  children: string;
};

const H4Type = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-top: 16px;
`;

const H4 = ({ children }: H4Props) => <H4Type>{children}</H4Type>;

export { H4 };
